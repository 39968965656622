import React, { useEffect, useState } from 'react';

const defaultProps = {
  play: true,
  style: { width: 150, height: 150 },
  loop: true,
}

const LottieImage = props => {
  const [ LazyComponent, setLazyComponent ] = useState(null)
  useEffect(() => {
    const loadLazyComponent = async () => {  
      const { default: lazyModule} = await import('react-lottie-player');
      const animationData = await import(`/static/lotties/${props.json}`)
      const componentProps = {
        // pass props to component here
        ...defaultProps,
        ...props,
        animationData,
      }
      const LazyElement = React.createElement(lazyModule, componentProps)
      setLazyComponent(LazyElement)
    }
    if (LazyComponent === null) {
      loadLazyComponent()
    }
    return () => setLazyComponent(null)
  }, [])
  return (
    <>
      {LazyComponent}
    </>
  )
}

export default LottieImage;
