/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = (props) => {
  const { thumbnail, siteUrl, title, description, image, seo: seoData, type = 'website', pathName } = props;
  const seo = {
    title: title,
    description: ((description && description.description) || description || '').substring(0, 168),
    image: seoData.image || image && image.gatsbyImageData && image.gatsbyImageData.images && image.gatsbyImageData.images && image.gatsbyImageData.images.fallback.src,
    siteUrl,
    url: `${siteUrl}/${pathName}`,
    type,
  };

  return (
    <React.Fragment>
      <Helmet
        title={seo.title}
        titleTemplate={seo.title}
        defer={false}
        >
        {seo.type && <meta property="og:type" content={seo.type} />}
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content={`${seo.description} ...`} /> 

        {seo.url && <meta property="og:url" content={seo.url || 'https://www.conexionberlin.com'} />}
        {seo.title && (
          <meta property="og:title" content={seo.title} />
        )}
        {seo.description && (
          <meta
            property="og:description"
            content={seo.description}
          />
        )}
        {seo.image && (
          <meta property="og:image" content={seo.image} />
        )}
        {thumbnail && (
          <meta name="twitter:image" content={`https: ${thumbnail.fixed.src}`} />
        )}
      </Helmet>
    </React.Fragment>
  );
};

export default SEO;
