import {useEffect, useState} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useMatchedQuery = () => {
  const [breakpoint, setBreakpoint] = useState();
  const theme = useTheme();
  const matched = {};
  matched.xs = useMediaQuery(theme.breakpoints.only('xs'));
  matched.sm = useMediaQuery(theme.breakpoints.only('sm'));
  matched.md = useMediaQuery(theme.breakpoints.only('md'));
  matched.lg = useMediaQuery(theme.breakpoints.only('lg'));
  matched.xl = useMediaQuery(theme.breakpoints.only('xl'));
  useEffect(() => {
    setBreakpoint(Object.keys(matched).find(m => matched[m]));
  }, [matched]);
  return breakpoint;
}

export default useMatchedQuery;

